<template>
    <div class="box">
        <a-spin :spinning="loading">
            <div class="content" v-html="info.Content"></div>
        </a-spin>
        
    </div>
</template>
<script>
import { GetSingleDataById } from '@/api/common'
export default {
    metaInfo() {
		return {
			title: this.$route.meta.title || '文物出版社',
			meta: [
                {
                    name: 'keyWords',
					content: this.$route.meta.title || '文物出版社'
                },
                {
                    name: 'description',
					content: this.$route.meta.title || '文物出版社'
                }
            ]
		}
	},
    data(){
        return{
            info:{
                Content:'',
                UpdateTime:''
            },
            params:{
                id:0,
                columnId:10024
            },
            loading:false,
        }
    },
    methods:{
        async getData(){
            this.loading = true
            const { data } = await GetSingleDataById(this.params)
            this.info = data
            this.loading = false
        },
    },
    mounted(){
        this.getData()
    }
}
</script>
<style scoped lang="less">
.box{
    padding: 20px 40px 40px;
}
</style>